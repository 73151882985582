<template>
  <div class="news">
    <div class="banner">
      <div class="font-weight-bold">
        Publications
      </div>
    </div>
    <div class="list">

      <p class="item">
        <cite class="title">IGSF8 is an innate immune checkpoint and cancer immunotherapy target</cite>
        <span class="date">Cell, Apr 2024</span>
        <span style="font-size: 24px;font-weight:600;">
          <a style="margin-right: 20px;" href="//gv20tx.com/media/publications/IGSF8_Cell_paper.full.pdf">Full paper</a>
        </span>
      </p>
      <p class="item">
        <cite class="title">IGSF8 is a Novel Innate Immune Checkpoint and Cancer Immunotherapy Target</cite>
        <span class="date">AACR 2024, Apr 2024</span>
        <span style="font-size: 24px;font-weight:600;">
          <a style="margin-right: 20px;" href="//gv20tx.com/media/publications/20240408-GV20-AACR.pdf">Presentation</a>
        </span>
      </p>
      <p class="item">
        <cite class="title">IGSF8 is an innate immune checkpoint and cancer immunotherapy target</cite>
        <span class="date">ESMO Congress 2023, Oct 2023</span>
        <span style="font-size: 24px;font-weight:600;">
          <a style="margin-right: 20px;" href="//gv20tx.com/media/publications/20231017-ESMO-abstract-for-Website.pdf">Abstract</a>
          <a href="//gv20tx.com/media/publications/20231021-Karim_Benhadji_ESMO2023_Presentation_Final.pdf">Presentation</a>
        </span>
      </p>

    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {
    }
  },
  components: {
    Footers,
  }
}
</script>
<style lang="scss" scope>
.news {
  a {
    color: #000;
    &:hover {
      text-decoration: none;
    }
  }
  .banner {
    color: #fff;
    height: 256px;
    background: url(../assets/news/headline_bg.jpg) no-repeat center
      center/cover;
    font-size: 60px;
    display: flex;
    align-items: center;
    padding-left: 40px;
  }
  .list
  {
    margin: 80px 40px;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    .item
    {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 40px 0px;
      border-top: 1px solid #979797;
      &:first-child
      {
        border-top: none;
      }
      .date
      {
        margin-top: 20px;
        height: 60px;
        font-size: 32px;
        font-weight: bold;
        color: #6D7278;
        line-height: 60px;
      }
      .title
      {
        font-size: 40px;
        color: #2C2E30;
        line-height: 56px;
        font-style: normal;
      }
      .picture
      {
        width: 235px;
        height: 180px;
      }
    }
  }
}
</style>
